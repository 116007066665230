import React, { useState } from 'react'
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  StorageApi
} from '../MainCode/MainImport'
import { useSession, getSession } from "next-auth/react"
import Image from 'next/image';
import { useEffect } from 'react';
import { message } from 'antd';


const menuaktif = "inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 focus:shadow-outline ease-in-out transform border-r-8 border-[#312E81] bg-neutral-300 active:block";
const menunonaktif = "inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 focus:shadow-outline ease-in-out transform hover:border-r-8 hover:border-[#312E81] hover:bg-neutral-300";

function SideBar(props) {
  const router = useRouter();
  const [openmenu, setOpenmenu] = useState([])
  const [menuparent, setMenuParent] = useState([])
  const [menusubparent, setMenuSubParent] = useState([])
  const [loading, setLoading] = useState(false)
  const { data: session, status } = useSession()
  const link_akses = router.asPath.split('/')
  const link_aksess = router.asPath
  const link_aktif = link_akses[link_akses.length - 1]

  useEffect(() => {
    MenuParent();
    MenuSubParent();
  }, [loading])

  const subMenuOnMouseEnter = (subMenuId) => {
    setOpenmenu((prev) => {
      let arr = [...prev]
      arr[subMenuId] = true
      return arr
    })
  }

  const subMenuOnMouseDown = (subMenuId) => {
    setOpenmenu((prev) => {
      let arr = [...prev]
      arr[subMenuId] = false
      return arr
    })
  }

  const MenuParent = async () => {
    const sesi = await getSession()
    let arr = []
    if (sesi.kode_unit) { arr = [...arr, `kode_unit=${sesi.kode_unit}`] }
    if (sesi.kode_sub_unit) { arr = [...arr, `kode_sub_unit=${sesi.kode_sub_unit}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    Promise.resolve(StorageApi.getData(`sm_hrm/page_group` + param))
      .then(value => {
        const detail = value.data.data
        setMenuParent(detail)
      }).catch(error => {
        setMenuParent([])
      })
  }

  const MenuSubParent = async () => {
    const sesi = await getSession()
    let arr = []
    if (sesi.kode_unit) { arr = [...arr, `kode_unit=${sesi.kode_unit}`] }
    if (sesi.kode_sub_unit) { arr = [...arr, `kode_sub_unit=${sesi.kode_sub_unit}`] }
    let param = '';
    param = arr.length > 0 ? '&' + arr.join('&') : '';
    Promise.resolve(StorageApi.getData(`sm_hrm/page_group?level=2` + param))
      .then(value => {
        const detail = value.data.data
        setMenuSubParent(detail)
      }).catch(error => {
        setMenuSubParent([])
      })
  }

  const cekHalaman = (daftar_menu)=>{
      const hasil =  daftar_menu.filter((tes) => `/${tes.link.trim()}` == link_aksess)
      return hasil
  }


  return (
    <div>
      <nav className="flex-1 bg-gray-900 ">
        <ul>
          {menuparent.map(item => {
            const page_count = item.link.split('/')
            const page = page_count[page_count.length - 1]
            const cekLink = cekHalaman(menuparent)
            var id = item.rowid
            if (cekLink.length > 0 || link_aksess == session?.redirect.trim() || link_aksess == "/Hrm/profile" ) {
              if (item.level == 1 && item.link != '') {
                return (
                  <li className='mb-2' key={item.rowid}>
                    <Link href={`/${item.link}`}>
                      <a className={(link_aktif == page.trim()) ? menuaktif : menunonaktif}>
                        <div className="container flex">
                          <button className="flex items-center">
                            {item.icon ? (<Image loader={e => { return item.icon }} src={item.icon} alt="image icon" width={20} height={20} unoptimized />) : ''}
                            <span className={`${(link_aktif == page.trim()) ? " text-gray-900" : "text-white"} ml-2 text-[14px] font-semibold hover:text-gray-900`}>{item.page}</span>
                          </button>
                        </div>
                      </a>
                    </Link>
                  </li>
                )
              }

              if (item.link == "") {
                return (
                  <li onMouseLeave={() => subMenuOnMouseDown(item.rowid)} onMouseEnter={() => subMenuOnMouseEnter(item.rowid)} key={item.rowid}>
                    <a className={menunonaktif} >
                      <div className="container flex justify-between">
                        <button className="flex items-center">
                          {item.icon ? <Image loader={e => { return item.icon }} src={item.icon} alt="image icon" width={20} height={20} unoptimized /> : ""}
                          <span className={`${(link_aktif == page.trim()) ? " text-gray-900" : "text-white"} ml-2 text-[14px] font-semibold hover:text-gray-900`}>{item.page}</span>
                        </button>
                        <Image src={`/icons/${openmenu ? 'down-arrowP' : 'up-arrowP'}.svg`} alt="image" width={20} height={20} />
                      </div>
                    </a>
                    <ul className={`${openmenu ? 'block' : 'block'}`}>
                      {openmenu[item.rowid] && menusubparent.map(item => {
                        const sub_count = item.link.split('/')
                        const subpage = sub_count[sub_count.length - 1]
                        if (id == item.parent_menu) {
                          return (
                            <>
                              <li className='ml-5' key={item.rowid}>
                                <Link href={`/${item.link}`}>
                                  <a className={(link_aktif == subpage.trim()) ? menuaktif : menunonaktif}>
                                    <div className="container flex hover:text-gray-900">
                                      <span className={`${(link_aktif == subpage.trim()) ? " text-gray-900" : "text-white"} ml-2 text-[14px] font-semibold hover:text-gray-900`}>{item.page}</span>
                                    </div>
                                  </a>
                                </Link>
                              </li>
                            </>
                          )
                        }
                      })}
                    </ul>
                  </li>
                )
              }
            }else{
              router.push(session?.redirect)
            }
          })}
        </ul>
      </nav>
    </div >
  )
}

export default SideBar