import React from 'react'
import { DatePicker } from 'antd'
import Image from 'next/image'

const FilterDate = (props) => {

  return (
    <>
      <div className='grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 justify-center items-center gap-3 mb-10'>
        <div className="">
          <label htmlFor="" className='text-white'>Dari Tanggal :</label>
          <DatePicker className='w-full' onChange={props.dari} picker="date" />
        </div>
        <div className="">
          <label htmlFor="" className='text-white'>Sampai Tanggal :</label>
          <DatePicker className='w-full' onChange={props.sampai} picker="date" />
        </div>
        <div className="">
          <button className="mt-5 py-1 px-2 rounded-md bg-slate-400 hover:bg-sky-700 font-semibold flex text-white" onClick={props.filter}> <Image src="/icons/search.svg" alt="image" width={20} height={20} className="mr-2" /> Filter</button>
        </div>
        {props.download ? (<>
          <div className="flex items-center justify-end">
            <button className=" -mt-14 xl:mt-5 md:mt-5 py-3 px-4 rounded-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white" onClick={props.download}> <Image src="/icons/download.svg" alt="image" width={20} height={20} className="mr-2" /> Download</button>
          </div>
        </>) : (<></>)}
      </div>
    </>
  )
}
export default FilterDate;