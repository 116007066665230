import React from 'react'
import Image from "next/image"

export default function LoginLayouts(props) {
  return (
    <>
      <link rel="icon" href="/images/algorskorl.png"></link>
      <div className={`bg-[url('/images/bgd-hrm.png')] bg-cover bg-center ${props.BgFull ? 'h-full' : 'h-screen'}`}>
        <div className="grid mx-auto justify-center">
          <div className="mx-auto mt-6">
            <div className="text-center">
              <Image src='/images/algorskorl.png' width={100} height={100} />
              <h1 className="font-bold text-[32px] text-white">{props.title}</h1>
              <h3 className="font-light text-[14px] text-white ">{props.subtitle}</h3>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </>
  )
}
