import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Avatar, message } from 'antd'
import { getSession, signOut, useSession } from "next-auth/react"
import Image from 'next/image'
import Link from 'next/link'
import { useIdleTimer } from 'react-idle-timer'
import StorageApi from '../APIConfig/MainApi'
import { Cookies } from 'react-cookie'

const ProfileMenu = (props) => {
  const [arr, setArr] = useState([]);
  const [openMenu, setOpenMenu] = useState(false)
  const router = useRouter();
  const { data: session, status } = useSession()
  useEffect(() => {
    getSess();
    checkSession();
  }, []);


  const logout = async (e) => {
    const sessi = await getSession()
    Promise.resolve(StorageApi.hapusDataLogin("sso_check", sessi.id_unique))
      .then(value => {
        message.success("Berhasil logout")
        hapus_sessi()
      }).catch(error => {
        hapus_sessi()
      })
  }

  const hapus_sessi = async () => {
    await signOut({
      redirect: false
    });
    getSess();
  }

  async function getSess() {
    const session = await getSession()
    if (!session) {
      router.push('/');
    }
  }

  const onIdle = () => {
    logout()
    message.info("Otomatis logout, karena dalam posisi standby selama 30 menit")
  }

  const checkSession = async () => {
    const sesi = await getSession()
    Promise.resolve(StorageApi.detailData('sso_check/checksession', sesi.id_unique))
      .then(value => {
        if (!value.status) {
          hapus_sessi()
          message.success("Akun ini logout paksa")
        }
      }).catch(error => {
        message.success("Akun ini logout paksa")
        hapus_sessi()
      })
  }

  useIdleTimer({ onIdle, timeout: 1800 * 1000 })



  const menunonaktif = "inline-flex items-center w-full px-4 py-2 mt-1 text-base text-gray-900 transition duration-500 focus:shadow-outline ease-in-out transform hover:border-r-8 hover:border-[#003AD2] hover:bg-[#E5E5E5]";
  return (
    <div>
      <div className='flex grid-cols-2 gap-2 xl:mx-[60px]'>
        <div className='' onClick={e => setOpenMenu(!openMenu)}>
          <Avatar src={session?.image ? session?.image : "/images/profile.png"} size={50} />
        </div>
        <div className=''>
          <div className='text-start' onClick={e => setOpenMenu(!openMenu)}>
            <button className='xl:block md:block hidden font-medium capitalize text-[11px] xl:text-[14px] md:text-[14px] text-gray-200'>{session?.nama}
              <div>
                <div className='flex'>
                  <span className='flex-none uppercase text-[8px] xl:text-[9px] md:text-[10px]'>{session?.unit_fungsional} </span>
                </div>
              </div>
            </button>
            <div className={`bg-white  z-50 border mx-auto xl:w-1/6 md:w-1/6 w-1/3 rounded-md absolute mt-12 -ml-20 md:mt-3 md:-ml-12 xl:mt-3 xl:-ml-12 ${openMenu ? 'block' : 'hidden'}`}>
              <nav className="flex-1">
                <div className='text-center font-bold p-2 xl:hidden md:hidden block'>
                  <p className='flex-none uppercase text-[8px] xl:text-[9px] md:text-[10px] -mb-0.5'>{session?.nama} </p>
                  <p className='flex-none uppercase text-[6px]'>{session?.unit_fungsional} </p>
                </div>

                <ul className="">
                  <li>
                    <a className={menunonaktif}>
                      <div className="container flex">
                        <Link href={'/Hrm/profile'}>
                          <button className="flex">
                            <Image src={'/icons/profile.svg'} alt="image" width={20} height={20} />
                            <span className="ml-2 text-[14px]">Profile</span>
                          </button>
                        </Link>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className={menunonaktif}>
                      <div className="container flex">
                        <button className="flex" onClick={logout}>
                          <Image src={'/icons/logout.svg'} alt="image" width={20} height={20} />
                          <span className="ml-2 text-[14px]">Sign Out</span>
                        </button>
                      </div>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}


export default ProfileMenu
