import dynamic from 'next/dynamic';
import { useEffect } from 'react';
const MapsAddress = dynamic(import('./MapsDetail'), { ssr: false });

const MapDetail = ({ lat, lng, jalan }) => {

  useEffect(() => {

  })
  return (
    <MapsAddress jalan={jalan} lat={lat} lng={lng} />
  );
};

export default MapDetail