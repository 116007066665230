import React from 'react'
import Script from 'next/script'

function LoadScript() {
  return (
    <>
     <Script src="https://cdn.jsdelivr.net/npm/tw-elements/dist/js/index.min.js"/>
     <Script src="https://cdn.jsdelivr.net/npm/chart.js"/>
    </>
  )
}

export default LoadScript