import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { getSession, signOut, useSession } from "next-auth/react"
import dynamic from 'next/dynamic';
const MapsDetail = dynamic(import('../../components/Map/MapsDetail'), { ssr: false });
import { Modal, DatePicker } from 'antd'
import {
  StorageApi,
  DataTable,
  moment,
  ActionTable,
  SettingApi,
  SelectRenGiatJenis,
  SelectDasarKegiatan,
  FilterDataCollecting,
  DivCustom,
  MapDetail,
} from "../MainCode/MainImport"

function BrandChumb(props) {
  const [renOpsList, setRenOpsList] = useState([]);
  const [renGiatList, setRenGiatList] = useState([]);
  const [renGiatMasList, setRenGiatMasList] = useState([]);
  const [dari, setTglDari] = useState('');
  const [sampai, setTglSampai] = useState('');
  const [showPeta, setshowPeta] = useState(false);
  const [rowid, setRowId] = useState('');
  const [lat, setLat] = useState('');
  const { data: session, status } = useSession()
  const [lng, setLng] = useState('');
  const [loading, setLoading] = useState(false);
  const [valid, setValidasi] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [aksi, SetAksi] = useState('');
  const [titleModal, setTitleModal] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    validasi();
  }, [loading])

  const validasi = async () => {
    const session = await getSession()
    Promise.resolve(StorageApi.getData(`sm_hrm/role_user?kode_user_master=${session.kode_user_master}`))
      .then(value => {
        const detail = value.data.data[0]
        setValidasi(detail)
      }).catch(error => {
        console.log(error);
      })
  }

  const reset_elm = () => {
    setTglDari('')
    setTglSampai('')
    setRowId('')
    setLat('')
    setLng('')
  }
  const renOps = [
    { title: 'Tanggal', dataIndex: 'tanggal' },
    { title: 'Lokasi', dataIndex: 'lokasi', align: 'center' },
    { title: 'Jenis Kegiatan', dataIndex: 'jenis_kegiatan', align: 'center' },
    { title: 'Dasar Kegiatan', dataIndex: 'dasar_giat' },
    { title: 'Kategori', dataIndex: 'kategori_giat' },
    { title: 'Penanggung Jawab', dataIndex: 'penanggung_jawab' },
  ];


  const renGiat = [
    { title: 'Tanggal', dataIndex: 'tanggal' },
    { title: 'Lokasi', dataIndex: 'lokasi', align: 'center' },
    { title: 'Kategori', dataIndex: 'kategori_giat' },
    { title: 'Jenis Kegiatan', dataIndex: 'jenis_kegiatan' },
    { title: 'Bentuk Giat', dataIndex: 'bentuk_giat' },
    { title: 'Penanggung Jawab', dataIndex: 'penanggung_jawab' },
  ];

  const renGiatMas = [
    { title: 'Tanggal', dataIndex: 'tanggal' },
    { title: 'Lokasi', dataIndex: 'lokasi', align: 'center' },
    { title: 'Jenis Kegiatan', dataIndex: 'jenis_kegiatan', align: 'center' },
    { title: 'Jumlah Peserta', dataIndex: 'jumlah_peserta' },
    { title: 'Penanggung Jawab', dataIndex: 'penanggung_jawab' },
  ];

  const ShowModal = async (param, id, lat, lng) => {
    setIsModalVisible(true);
    SetAksi(param);
    const title = param.charAt(0).toUpperCase() + param.slice(1)
    setTitleModal(title + " Data ")
    if (param == "renOps") {
      const sessi = await getSession()
      let arr = []
      if (dari) { arr = [...arr, `tgl_start=${dari}`] }
      if (sampai) { arr = [...arr, `tgl_end=${sampai}`] }
      if (sessi.polda_id) { arr = [...arr, `polda_id=${sessi.polda_id}`] }
      if (sessi.polres_id) { arr = [...arr, `polres_id=${sessi.polres_id}`] }
      let param = '';
      param = arr.length > 0 ? '?' + arr.join('&') : '';
      setLoader(true)
      Promise.resolve(StorageApi.getData('data_collecting/renops_pol' + param))
        .then(value => {
          const list = value.data.data
          var data = []
          const i = 0 + 1
          list.map(item => {
            data.push({
              key: item.rowid,
              no: i++,
              tanggal: moment(item.tanggal).format('DD MMM YYYY'),
              lokasi: <>
                <ActionTable showMaps={e => ShowModal('renOps Maps', item.rowid, item.lat, item.lng)} />
              </>,
              jenis_kegiatan: item.jenis_kegiatan,
              dasar_giat: item.dasar_giat,
              kategori_giat: item.kategori_kegiatan,
              penanggung_jawab: item.penanggung_jawab,
            });
          });
          setRenOpsList(data)
          setLoader(false)
        }).catch(error => {
          setRenOpsList([])
          setLoader(false)
        })
    }
    if (param == "renGiat") {
      const sessi = await getSession()
      let arr = []
      if (dari) { arr = [...arr, `tgl_start=${dari}`] }
      if (sampai) { arr = [...arr, `tgl_end=${sampai}`] }
      if (sessi.polda_id) { arr = [...arr, `polda_id=${sessi.polda_id}`] }
      if (sessi.polres_id) { arr = [...arr, `polres_id=${sessi.polres_id}`] }
      let param = '';
      param = arr.length > 0 ? '?' + arr.join('&') : '';
      setLoader(true)
      Promise.resolve(StorageApi.getData('data_collecting/rengiat' + param))
        .then(value => {
          const list = value.data.data
          var data = []
          const i = 0 + 1
          list.map(item => {
            data.push({
              key: item.rowid,
              no: i++,
              tanggal: moment(item.tanggal).format('DD MMM YYYY'),
              lokasi: <>
                <ActionTable showMaps={e => ShowModal('renGiat Maps', item.nama_jalan_s, item.lat, item.lng)} />
              </>,
              kategori_giat: item.kategori_kegiatan,
              jenis_kegiatan: item.jenis_kegiatan,
              bentuk_giat: item.bentuk_giat,
              penanggung_jawab: item.penanggung_jawab,
            });
          });
          setRenGiatList(data)
          setLoader(false)
        }).catch(error => {
          setRenGiatList([])
          setLoader(false)
        })
    }

    if (param == "renGiatMas") {
      const sessi = await getSession()
      let arr = []
      if (dari) { arr = [...arr, `tgl_start=${dari}`] }
      if (sampai) { arr = [...arr, `tgl_end=${sampai}`] }
      if (sessi.polda_id) { arr = [...arr, `polda_id=${sessi.polda_id}`] }
      if (sessi.polres_id) { arr = [...arr, `polres_id=${sessi.polres_id}`] }
      let param = '';
      param = arr.length > 0 ? '?' + arr.join('&') : '';
      setLoader(true)
      Promise.resolve(StorageApi.getData('data_collecting/rengiat_masyarakat' + param))
        .then(value => {
          const list = value.data.data
          var data = []
          const i = 0 + 1
          list.map(item => {
            data.push({
              key: item.rowid,
              no: i++,
              tanggal: moment(item.tanggal).format('DD MMM YYYY'),
              lokasi: <>
                <ActionTable showMaps={e => ShowModal('renGiatMasyarakat Maps', item.rowid, item.lat, item.lng)} />
              </>,
              jenis_kegiatan: item.jenis_kegiatan,
              jumlah_peserta: item.jumlah_peserta + " Orang",
              penanggung_jawab: item.penanggung_jawab,
            });
          });
          setRenGiatMasList(data)
          setLoader(false)
        }).catch(value => {
          setRenGiatMasList([])
          setLoader(false)
        })
    }

    if (param == "renOps Maps" || param == "renGiat Maps" || param == "renGiatMasyarakat Maps") {
      setLat(lat)
      setLng(lng)
      setRowId(id)
    }
  }

  const handleOk = (aksi) => {
    if (aksi == "renGiat Maps") {
      ShowModal("renGiat")
    } else if (aksi == "renOps Maps") {
      ShowModal("renOps")
    } else if (aksi == "renGiatMasyarakat Maps") {
      ShowModal('renGiatMasyarakat Maps')
    } else {
      setIsModalVisible(false)
    }
  }
  return (
    <>
      <div>
        <div className="xl:flex justify-between px-4 py-4 sm:px-6">
          <div className="">
            <h1 className="text-lg text-gray-800 font-bold tracking-[1px] mb-8">{props.subTitle}</h1>
          </div>
          <div className="items-center">
            <div className='grid xl:flex md:flex gap-3 md:justify-center'>
              {props.renGiat ? (<>
                <button className="py-3 px-4 justify-center items-center rounded-md bg-[#7366FF] hover:bg-[#3f3892] font-semibold flex text-white" onClick={e => ShowModal("renGiat")}>
                  Rengiat
                </button>
              </>) : ("")}
              {props.renOps ? (<>
                <button className="py-1 justify-center items-center px-4 rounded-md bg-[#0980db] hover:bg-[#0980dba2] font-semibold flex text-white" onClick={e => ShowModal("renOps")}>
                  RenOps
                </button>
              </>) : ("")}
              {props.renGiatMas ? (<>
                <button className="py-1 justify-center items-center px-4 rounded-md bg-[#F8B400] hover:bg-[#f8b60090] font-semibold flex text-white" onClick={e => ShowModal("renGiatMas")}>
                  Rengiat Masyarakat
                </button>
              </>) : ("")}
              {props.satuPeta ? (<>
                <button className="py-1 items-center px-4 rounded-md bg-[#02cff3] hover:bg-[#02cff384] font-semibold flex text-white" onClick={e => setshowPeta(!showPeta)}>
                  {showPeta ? "Close Peta" : "Satu Peta"}
                </button>
                {/* <button className="py-1 items-center px-4 rounded-md bg-[#02cff3] hover:bg-[#02cff384] font-semibold flex text-white" onClick={e => ShowModal("satuPeta")}>
                  Satu Peta
                </button> */}
              </>) : ("")}
              {props.Adddata && valid.tambah ? (
                <button className="py-2 px-3 justify-center items-center rounded-md bg-[#5fc52e] hover:bg-[#5ec52e8b] font-semibold flex text-white" onClick={props.onClick}>
                  <Image src="/icons/plus.svg" alt="tambah" className="flex mr-2" width={15} height={15} /> &nbsp;Add New
                </button>
              ) : ("")}
            </div>

            {props.Downloaddata ? (
              <button className="items-center float-right py-3 px-4 rounded-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white" onClick={props.onClick}>
                <Image src="/icons/download.svg" alt="tambah" className="flex mr-2" width={15} height={15} />&nbsp;Download
              </button>
            ) : ("")}
          </div>
        </div>
        <Modal title={titleModal} visible={isModalVisible} onOk={e => handleOk(e, aksi)} onCancel={e => {reset_elm();setIsModalVisible(!isModalVisible)}} width={1200}>
          {(aksi == "renOps" ? (<>
            <FilterDataCollecting
              dari={
                <DivCustom label="Dari Tanggal :"
                  form={
                    <DatePicker className='w-full' value={dari ? moment(dari, 'YYYY-MM-DD') : ""} placeholder="Dari tanggal" onChange={(e, d) => setTglDari(d)} />
                  }
                />
              }
              sampai={
                <DivCustom label="Sampai Tanggal :"
                  form={
                    <DatePicker className='w-full' value={sampai ? moment(sampai, 'YYYY-MM-DD') : ""} placeholder="Sampai tanggal" onChange={(e, d) => setTglSampai(d)} />
                  }
                />
              }
              filter={(e) => ShowModal('renOps')} reset={(e) => {
                reset_elm()
                ShowModal('renOps')
              }} />
            <DataTable title={'List Ren Ops'} columns={renOps} dataSource={renOpsList} loading={loader} />
          </>) : (<></>))}
          {(aksi == "renGiatMas" ? (<>
            <FilterDataCollecting
              dari={
                <DivCustom label="Dari Tanggal :"
                  form={
                    <DatePicker className='w-full' value={dari ? moment(dari, 'YYYY-MM-DD') : ""} placeholder="Dari tanggal" onChange={(e, d) => setTglDari(d)} />
                  }
                />
              }
              sampai={
                <DivCustom label="Sampai Tanggal :"
                  form={
                    <DatePicker className='w-full' value={sampai ? moment(sampai, 'YYYY-MM-DD') : ""} placeholder="Sampai tanggal" onChange={(e, d) => setTglSampai(d)} />
                  }
                />
              }
              filter={(e) => ShowModal('renGiatMas')} reset={(e) => {
                reset_elm()
                ShowModal('renGiatMas')
              }} />
            <DataTable title={'List Ren Giat Masyarakat'} columns={renGiatMas} dataSource={renGiatMasList} loading={loader} />
          </>) : (<></>))}
          {(aksi == "renGiat" ? (<>
            <FilterDataCollecting
              dari={
                <DivCustom label="Dari Tanggal :"
                  form={
                    <DatePicker className='w-full' value={dari ? moment(dari, 'YYYY-MM-DD') : ""} placeholder="Dari tanggal" onChange={(e, d) => setTglDari(d)} />
                  }
                />
              }
              sampai={
                <DivCustom label="Sampai Tanggal :"
                  form={
                    <DatePicker className='w-full' value={sampai ? moment(sampai, 'YYYY-MM-DD') : ""} placeholder="Sampai tanggal" onChange={(e, d) => setTglSampai(d)} />
                  }
                />
              }
              filter={(e) => ShowModal('renGiat')} reset={(e) => {
                reset_elm()
                ShowModal('renGiat')
              }} />
            <DataTable title={'List Ren Giat'} columns={renGiat} dataSource={renGiatList} loading={loader} />
          </>) : (<></>))}

          {(aksi == "satuPeta" ? (<>

          </>) : (<></>))}

          {(aksi == "renGiat Maps" || aksi == "renOps Maps" || aksi == "renGiatMasyarakat Maps" ? (<>
            <div className=''>
              <div className='!w-full relative !h-[60vh] mt-4'><MapDetail lat={lat} lng={lng} jalan={rowid} /></div>
              {/* <iframe className='relative !h-[90vh] !w-full' src={`${SettingApi.url_satu_peta}?k=${session?.token_login}&${aksi == "renGiat Maps" ? "giat" : aksi == "renOps Maps" ? "ops" : "giat_m"}=${lat},${lng},${rowid}&display=off`} frameBorder="0"></iframe> */}
            </div>
          </>) : (<></>))}
        </Modal>
      </div>
          {showPeta && <iframe className='hidden' src={`${SettingApi.url_satu_peta}?u=${session?.token_login}&b=${session?.bearer}&btn_out=off`}></iframe>}
    </>

  )
}

export default BrandChumb
