import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import { message, Tooltip, Popconfirm } from 'antd'
import { getSession } from "next-auth/react"
import {
  StorageApi
} from "../MainCode/MainImport"


function ActionTable(props) {
  const [loading, setLoading] = useState(false);
  const [valid, setValidasi] = useState(false);

  useEffect(() => {
    validasi();
  }, [loading])

  const validasi = async () => {
    const session = await getSession()
    Promise.resolve(StorageApi.getData(`sm_hrm/role_user?kode_user_master=${session.kode_user_master}`))
      .then(value => {
        const detail = value.data.data[0]
        setValidasi(detail)
      }).catch(error => {
        console.log(error);
      })
  }

  return (
    <>
      {props.edit && valid.edit || props.editP ? (<>
        <Tooltip placement="left" title={"Edit"}>
          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.edit ? props.edit : props.editP} >
            <Image src={`/icons/${props.editP ? "pencil.svg" : "editW.svg"}`} width={25} height={23} alt={'edit'} />
          </button>
        </Tooltip>
      </>) : (<></>)}
      {props.detail ? (<>
        <Tooltip placement="left" title={"Detail"}>
          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.detail} >
            <Image src='/icons/search-file.png' width={23} height={23} alt={'detail'} />
          </button>
        </Tooltip>

      </>) : (<></>)}
      {props.history ? (<>
        <Tooltip placement="left" title={"History lokasi"}>
          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.history} >
            <Image src='/icons/Hlocation.png' width={23} height={23} alt={'detail'} />
          </button>
        </Tooltip>
      </>) : (<></>)}

      {props.logout ? (<>
        <Tooltip placement="left" title={"Logout Paksa"}>
          <Popconfirm placement="topLeft" title={"User ini akan di logout paksa, apakah anda yakin ?"} onConfirm={props.logout} okText="Yes" cancelText="No">
            <Image src='/icons/logout.svg' width={23} height={23} alt={'detail'} className="cursor-pointer" />
          </Popconfirm>
        </Tooltip>

      </>) : (<></>)}
      {props.task ? (<>
        <Tooltip placement="left" title={"Riwayat Tugas"}>
          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.task} >
            <Image src='/icons/task.png' width={23} height={23} alt={'detail'} />
          </button>
        </Tooltip>

      </>) : (<></>)}
      {props.user ? (<>
        <Tooltip placement="left" title={"User Detail"}>
          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.user} >
            <Image src='/icons/user.svg' width={30} height={23} alt={'user detail'} />
          </button>
        </Tooltip>

      </>) : (<></>)}
      {props.delete && valid.hapus || props.deleteP ? (<>
        <Tooltip placement="left" title={"Hapus"}>
          <button className="hover:text-blue-500 ml-1 border hover:border-[#326AE3] p-1 border-white rounded-sm " onClick={props.delete ? props.delete : props.deleteP} >
            <Image src={`/icons/${props.deleteP ? "trash.svg" : "trashW.svg"}`} width={25} height={23} alt={'delete'} />
          </button>
        </Tooltip>

      </>) : (<></>)}
      {props.alert ? (<>
        <Tooltip placement="left" title={"Alert"}>
          <button className="hover:text-blue-500 ml-1 border hover:border-[#326AE3] p-1 border-white rounded-sm " onClick={() => message.error('Data belum lengkap')} >
            <Image src='/icons/alert.svg' width={25} height={23} alt={'alert'} />
          </button>
        </Tooltip>
      </>) : (<></>)}
      {props.password && valid.password || props.passwordP ? (<>
        <Tooltip placement="left" title={"Ubah Password"}>
          <button className="hover:text-blue-500 ml-1 border hover:border-[#326AE3] p-1 border-white rounded-sm " onClick={props.password}>
            <Image src={`/icons/${props.passwordP ? "password.svg" : "passwordW.svg"}`} width={25} height={23} alt={'password'} />
          </button>
        </Tooltip>

      </>) : (<></>)}
      {props.detailpage ? (<>
        <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.detailpage} >
          <Image src='/icons/search-file.png' width={23} height={23} alt={'detail'} />
        </button>
      </>) : (<></>)}

      {props.showMaps ? (<>
        <Tooltip placement="left" title={"Show Maps"}>
          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.showMaps} >
            <Image src="/icons/marker.gif" alt="" width={40} height={40} />
          </button>
        </Tooltip>
      </>) : (<></>)}

      {props.teruskanData ? (<>
        <Tooltip placement="left" title={"Teruskan data"}>
          <button className="hover:text-blue-500 mr-1 border hover:border-[#326AE3] p-1 border-white rounded-sm" onClick={props.teruskanData} >
            <Image src="/icons/send.png" alt="" width={25} height={25} />
          </button>
        </Tooltip>
      </>) : (<></>)}
      {/* {props.detail && session?.kesatuan != "NASIONAL" && props.alert != "" ? (<>
      </>) : (<>
        {session?.kesatuan == "NASIONAL" && props.alert != "" && session?.polda_id == "" ? (<>
          {props.detail ? (<></>) : (<>
            {props.alert ? (<></>) : (<>
              <button className="hover:text-blue-500 ml-1 border hover:border-[#326AE3] p-1 border-white rounded-sm " onClick={() => message.error('Status anda tidak memungkinkan untuk melakukan tambah, edit, dan delete')} >
                <Image src='/icons/info.svg' width={25} height={23} alt={'delete'} />
              </button>
            </>)}
          </>)}
        </>) : (<></>)}

      </>)} */}
    </>
  )
}

export default ActionTable