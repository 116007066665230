import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';
import SideBar from '../Addons/SideBar';
import BrandChumb from '../Addons/BrandChumb';
import ProfileMenu from "../Addons/ProfileMenu";
import { onMessageListener, getToken } from "../../components/MainCode/firebaseConfig";
import LoadScript from '../Addons/LoadScript';
import { useEffect, useState } from 'react';
import { getSession, useSession } from "next-auth/react"
import { message } from 'antd';
import { useRouter } from "next/router";
import { SettingApi, StorageApi } from './MainImport';

export default function BaseLayout(props) {
  const router = useRouter();
  const [isOpen, Mobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [runing_teks, setRuningText] = useState([]);
  const { data: session, status } = useSession()
  const [token, setGetToken] = useState("");
  const [fcm_token, setFcmToken] = useState("");
  const [istokenfound, setTokenFound] = useState(false)
  var fcm_server_key = "AAAAVn9i44M:APA91bFb3uxb-Whj7Knt1tcngeX9UpGX5boIP_JYBT-zvJPSA5grTqQM3bHUMXpGMDLS_u-5iaTGzLN2OYT6qcjuYT6hcGiNDsvGxSYhZF7uqXdQF7Z7dHELWVBSnWW1QDDJXgRygFhU"
  useEffect(() => {
    GetRunningText()
    if (props.firebaseOn) {
      requestPermission()
      getToken(setTokenFound, setGetToken).then((res) => {
        if (session?.kode_unit == SettingApi.allow_notif[0] || session?.kode_unit == SettingApi.allow_notif[1]) {
          if (res) {
            setFcmToken(res);
            let topic = ''
            if (session?.kode_unit == SettingApi.allow_notif[0]) {
              topic = "public_service"
            }
            if (session?.kode_unit == SettingApi.allow_notif[1]) {
              topic = "opsdal"
            }
            subscribeTokenToTopic(res, topic)
          }
        }
      })
    }
    getSess();
  }, [loading])

  async function getSess() {
    const session = await getSession()
    if (!session) {
      router.push('/');
    }
  }

  async function requestPermission() {
    const permisson = await Notification.requestPermission()
    if (permisson === 'granted') {
      message.info("Notifikasi di izinkan")
    } else if (permisson === 'denied') {
      alert('You denied for the notification')
    }
  }

  if (props.firebaseOn) {
      onMessageListener().then((payload) => {
        message.info(`${payload.notification.body}`, 10)
      }).catch((err) => console.log("failed: ", err));
   
  }
     //set notification to firebase
     function subscribeTokenToTopic(api_token, topic) {
      fetch('https://iid.googleapis.com/iid/v1/' + api_token + '/rel/topics/' + topic, {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'key=' + fcm_server_key
        })
      }).then(response => {
        if (response.status < 200 || response.status >= 400) {
          throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
        console.log('Subscribed to "' + topic + '"');
      }).catch(error => {
        console.log("ERROR Subscribe to topic", error);
      })
    }

  const GetRunningText = async () => {
    var categori = await getSession()
    let arr = []
    if (categori.polda_id) { arr = [...arr, `broad_cast_to_polda=${categori.polda_id}`] }
    if (categori.polres_id) { arr = [...arr, `broad_cast_to_polres=${categori.polres_id}`] }
    let param = '';
    param = arr.length > 0 ? '?' + arr.join('&') : '';
    Promise.resolve(StorageApi.getData('sm_master_data/running_text_by_session' + param))
      .then(value => {
        var list = value.data.data;
        
        setRuningText(list)
      }).catch(error => {
        setRuningText([])
      })
  }

  return (

    <>
      <Head>
        <title>{props.pageTitle}</title>
        <link rel="icon" href="/images/algorskorl.png"></link>
        <LoadScript />
      </Head>
      <div className="flex h-screen overflow-hidden  bg-gray-800 text-[14px] ">
        <div className={` ${isOpen ? " -translate-x-0" : " -translate-x-full "} z-10 md:flex md:flex-shrink-0 transition duration-500 absolute md:relative md:translate-x-0`}>
          <div className="flex flex-col w-64 bg-gray-900">
            <div className="flex flex-col items-center flex-shrink-0 mt-8 mb-2">
              <Link href={`${session?.modul_url}`}>
                <a className="text-left focus:outline-none mb-3.5">
                  <Image className="inline-block" loader={e => { return session?.logo_bo ? session?.logo_bo : "/images/profile.png" }}
                    src={session?.logo_bo ? session?.logo_bo : "/images/profile.png"}
                    alt="image icon"
                    width={200} height={100} />
                </a>
              </Link>
              <div className="bg-red-700 p-3 rounded font-sans tracking-wider text-[16px] text-white font-bold justify-center w-11/12 text-center">
                <span>{session?.unit_fungsional}</span>
              </div>
              <button className="hidden rounded-lg focus:outline-none focus:shadow-outline">
                <svg fill="currentColor" viewBox="0 0 20 20" className="w-6 h-6">
                  <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
            <div className={` ${isOpen ? " pb-96 z-10" : ""}  flex flex-col flex-grow pt-2 overflow-y-auto scrollbar-hide bg-gray-900 border-r border-gray-900`}>
              <div className="flex flex-col flex-grow -mt-4 bg-gray-900">
                <SideBar aktif={props.aktif} />
              </div>
            </div>
          </div >
        </div >

        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="flex bg-gray-900 py-6 items-center">

            <div className="container flex ml-3">
              <button className={`mr-4 md:hidden ${isOpen ? " z-50" : ""} `} onClick={() => Mobile(!isOpen)}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white" strokeWidth={2}>
                  <path className={`${isOpen ? "hidden" : ""}`} strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  <path className={`${isOpen ? "" : "hidden"}`} strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
              <marquee direction="" width="100%" onMouseover="this.stop();" onMouseout="this.start();">
                <div className='relative overflow-visible inline float-left'>
                  <div className="inline">
                    {runing_teks.map(item => {
                      return(<>
                      &nbsp; &nbsp; <Image src={`/icons/${item.icon_name}`} width={20} height={20} alt="" /> &nbsp; <a className="no-underline font-bold text-red-700 text-base fancybox" data-fancybox-group="gallery" href="#!" title="THE ELECTRIC LIGHTING ACT: section 35">{`${item.judul_pesan}`}: {item.konten}</a> &nbsp; <Image src={`/icons/${item.icon_name}`} width={20} height={20} alt="" />
                      </>)
                    })}
                  </div>
                </div>
              </marquee>
            </div>
            <div className="container">
              <div className="flex justify-end">
                <ProfileMenu token={fcm_token} />
              </div>
            </div>
          </div>
          <div className="container bg-[#EAFDFC]">
            <main className=" flex-1 focus:outline-none">
              <div className="xl:p-6 h-screen overflow-y-auto scrollbar-hide ">
                <BrandChumb subTitle={props.subTitle} Downloaddata={props.Downloaddata} Adddata={props.Adddata == "hidden" ? false : true} renGiat={props.renGiat} renGiatMas={props.renGiatMas} renOps={props.renOps} satuPeta={props.satuPeta} onClick={props.onClick} />
                <div className="px-2">
                  {props.children}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );

}
