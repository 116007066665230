import React from 'react'
import { DatePicker } from 'antd'
import Image from 'next/image'

export function FilterPublicService(props) {
  return (
    <>
      <div className='px-3 grid xl:grid-cols-5 md:grid-cols-2 grid-cols-1 justify-center items-center gap-3'>
        {props.kategori ? (<>
          <div className=''>
            <p className='text-white'>Kategori :</p>
            {props.kategori}
          </div>
        </>) : (<></>)}
        {props.kategori ? (<>
          <div className=''>
            <p className='text-white'>Sub Kategori :</p>
            {props.subKategori}
          </div>
        </>) : (<></>)}
        {props.dari ? (<>
          <div className=''>
            <p className='text-white'>Dari Tanggal :</p>
            {props.dari}
          </div>
        </>) : ("")}
        {props.sampai ? (<>
          <div className=''>
            <p className='text-white'>Sampai Tanggal :</p>
            {props.sampai}
          </div>
        </>) : ("")}
        {props.reset || props.filter ? (
          <>
            <div className="flex mt-8">
              {props.reset ? (<>
                <button className="mt-1 py-1 px-4 rounded-l-md bg-slate-400 hover:bg-slate-500 font-semibold flex text-white" onClick={props.reset}>Reset</button>
              </>) : (<></>)}
              {props.filter ? (<>
                <button className="mt-1 py-1 px-4 rounded-r-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white " onClick={props.filter}>Filter</button>
              </>) : (<></>)}
            </div>
          </>
        ) : ("")}
      </div>
    </>
  )
}

export function FilterDataCollecting(props) {
  return (
    <>
      <div className='px-3 grid xl:grid-cols-5 md:grid-cols-2 grid-cols-1 justify-center items-center gap-3'>
        {props.blackTrouble ? (<>
          <div className=''>
            {props.blackTrouble}
          </div>
        </>) : ("")}
        {props.dasarGiat ? (<>
          <div className=''>
            {props.dasarGiat}
          </div>
        </>) : ("")}
        {props.jenisKegiatan ? (<>
          <div className=''>
            {props.jenisKegiatan}
          </div>
        </>) : ("")}
        <div className=''>
          {props.dari}
        </div>
        <div className=''>
          {props.sampai}
        </div>
        {props.reset || props.filter ? (
          <>
            <div className="flex ">
              {props.reset ? (<>
                <button className="mt-1 py-1 px-4 rounded-l-md bg-slate-400 hover:bg-slate-500 font-semibold flex text-white" onClick={props.reset}>Reset</button>
              </>) : (<></>)}
              {props.filter ? (<>
                <button className="mt-1 py-1 px-4 rounded-r-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white " onClick={props.filter}>Filter</button>
              </>) : (<></>)}
            </div>
          </>
        ) : ("")}
      </div>
    </>
  )
}

export function FilterDataPatroliCctv(props) {
  return (
    <>
      <div className='px-3 grid xl:grid-cols-5 md:grid-cols-2 grid-cols-1 justify-center items-center gap-3'>
        {props.dari ? (<>
          <div className=''>
            <p className='text-white'>Dari Tanggal :</p>
            {props.dari}
          </div>
        </>) : ("")}
        {props.sampai ? (<>
          <div className=''>
            <p className='text-white'>Sampai Tanggal :</p>
            {props.sampai}
          </div>
        </>) : ("")}
        {props.reset || props.filter ? (
          <>
            <div className="flex mt-8">
              {props.reset ? (<>
                <button className="mt-1 py-1 px-4 rounded-l-md bg-slate-400 hover:bg-slate-500 font-semibold flex text-white" onClick={props.reset}>Reset</button>
              </>) : (<></>)}
              {props.filter ? (<>
                <button className="mt-1 py-1 px-4 rounded-r-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white " onClick={props.filter}>Filter</button>
              </>) : (<></>)}
            </div>
          </>
        ) : ("")}
      </div>
    </>
  )
}

export function FilterJemensOprek(props) {
  return (
    <>
      <div className='px-3 grid xl:grid-cols-5 md:grid-cols-2 grid-cols-1 justify-center items-center gap-3'>
        {props.dari ? (<>
          <div className=''>
            <p className='text-white'>Dari Tanggal :</p>
            {props.dari}
          </div>
        </>) : ("")}
        {props.sampai ? (<>
          <div className=''>
            <p className='text-white'>Sampai Tanggal :</p>
            {props.sampai}
          </div>
        </>) : ("")}
        {props.reset || props.filter ? (
          <>
            <div className="flex mt-8">
              {props.reset ? (<>
                <button className="mt-1 py-1 px-4 rounded-l-md bg-slate-400 hover:bg-slate-500 font-semibold flex text-white" onClick={props.reset}>Reset</button>
              </>) : (<></>)}
              {props.filter ? (<>
                <button className="mt-1 py-1 px-4 rounded-r-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white " onClick={props.filter}>Filter</button>
              </>) : (<></>)}
            </div>
          </>
        ) : ("")}
      </div>
    </>
  )
}

export function FilterITAsset(props) {
  return (
    <>
      <div className='px-3 grid xl:grid-cols-5 md:grid-cols-2 grid-cols-1 justify-center items-center gap-3'>
        {props.dari ? (<>
          <div className=''>
            <p className='text-white'>Dari Tanggal :</p>
            {props.dari}
          </div>
        </>) : ("")}
        {props.sampai ? (<>
          <div className=''>
            <p className='text-white'>Sampai Tanggal :</p>
            {props.sampai}
          </div>
        </>) : ("")}
        {props.reset || props.filter ? (
          <>
            <div className="flex mt-8">
              {props.reset ? (<>
                <button className="mt-1 py-1 px-4 rounded-l-md bg-slate-400 hover:bg-slate-500 font-semibold flex text-white" onClick={props.reset}>Reset</button>
              </>) : (<></>)}
              {props.filter ? (<>
                <button className="mt-1 py-1 px-4 rounded-r-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white " onClick={props.filter}>Filter</button>
              </>) : (<></>)}
            </div>
          </>
        ) : ("")}
      </div>
    </>
  )
}

export function FilterOpdsal(props) {
  return (
    <>
      <div className='px-3 grid xl:grid-cols-5 md:grid-cols-2 grid-cols-1 justify-center items-center gap-3'>
        {props.dari ? (<>
          <div className=''>
            <p className='text-white'>Dari Tanggal :</p>
            {props.dari}
          </div>
        </>) : ("")}
        {props.sampai ? (<>
          <div className=''>
            <p className='text-white'>Sampai Tanggal :</p>
            {props.sampai}
          </div>
        </>) : ("")}
        {props.reset || props.filter ? (
          <>
            <div className="flex mt-8">
              {props.reset ? (<>
                <button className="mt-1 py-1 px-4 rounded-l-md bg-slate-400 hover:bg-slate-500 font-semibold flex text-white" onClick={props.reset}>Reset</button>
              </>) : (<></>)}
              {props.filter ? (<>
                <button className="mt-1 py-1 px-4 rounded-r-md bg-sky-400 hover:bg-sky-700 font-semibold flex text-white " onClick={props.filter}>Filter</button>
              </>) : (<></>)}
            </div>
          </>
        ) : ("")}
      </div>
    </>
  )
}
